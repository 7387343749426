import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'app' },
    component: Home
  },
  {
    path: '/protocolos',
    name: 'protocolos',
    meta: { layout: 'app' },
    component: () => import('../views/Protocolos.vue')
  },
  {
    path: '/facturas',
    name: 'facturas',
    meta: { layout: 'app' },
    component: () => import('../views/Facturas.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    meta: { layout: 'app' },
    component: () => import('../views/Contacto.vue')
  },
  {
    path: '/resumen-de-cuenta',
    name: 'resumen',
    meta: { layout: 'app' },
    component: () => import('../views/ResumenDeCuenta.vue')
  },
  {
    path: '/perfil',
    name: 'perfil',
    meta: { layout: 'app' },
    component: () => import('../views/Perfil.vue')
  },
  {
    path: '/pedido-de-material',
    name: 'pedido',
    meta: { layout: 'app' },
    component: () => import('../views/PedidoDeMaterial.vue')
  },
  {
    path: '/encuesta/bromatologia',
    name: 'encuestaBromatologia',
    meta: { layout: 'app' },
    component: () => import('../views/EncuestaBromatologia.vue')
  },
  {
    path: '/encuesta/veterinaria',
    name: 'encuestaVeterinaria',
    meta: { layout: 'app' },
    component: () => import('../views/EncuestaVeterinaria.vue')
  },
  {
    path: '/solicitar-usuario',
    name: 'register',
    meta: { layout: 'simple' },
    component: () => import('../views/Registro.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'simple' },
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/solicitar-usuario']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = sessionStorage.getItem('token') // Es válido el token?

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next()
})

export default router
