import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es'

import Icons from '../components/Icons'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    values: {
      navFacturas: {
        component: Icons,
        props: {
          name: 'nav-facturas'
        }
      },
      navContacto: {
        component: Icons,
        props: {
          name: 'nav-contacto'
        }
      },
      navResumen: {
        component: Icons,
        props: {
          name: 'nav-resumen'
        }
      },
      navProtocolos: {
        component: Icons,
        props: {
          name: 'nav-protocolos'
        }
      },
      navPedido: {
        component: Icons,
        props: {
          name: 'nav-pedido'
        }
      },
    },
  },
});
