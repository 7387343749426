import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "./plugins/vee-validate";
import vuetify from './plugins/vuetify';
import soapClient from './soapClient'
import { eventHub } from './eventHub';
import './main.css'

Vue.config.productionTip = false

Vue.prototype.$soapClient = soapClient
Vue.prototype.$eventHub = eventHub

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate: function () {
    this.$store.state.loggedIn = !!sessionStorage.getItem('token')
  }
}).$mount('#app')
