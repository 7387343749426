<template>
  <v-app>
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      fixed
      app
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          link
          :to="{name: 'protocolos'}"
        >
          <v-list-item-icon>
            <v-icon size="30">$navProtocolos</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Protocolos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'facturas'}"
        >
          <v-list-item-icon>
            <v-icon size="30">$navFacturas</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Facturas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'resumen'}"
        >
          <v-list-item-icon>
            <v-icon size="30">$navResumen</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Resumen de Cuenta</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'pedido'}"
        >
          <v-list-item-icon>
            <v-icon size="30">$navPedido</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Pedido de material</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'contacto'}"
        >
          <v-list-item-icon>
            <v-icon size="30">$navContacto</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Contacto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      color="primary darken-3"
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Laboratorio Alvarez</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="logout()">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fill-height fluid class="pa-0">

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <span class="footer">&copy; {{ new Date().getFullYear() }} - Diseño <a href="https://madison.com.ar" target="_blank" rel="nofollow">Madison</a> - Desarrollado por <a href="https://jaque.com.ar" target="_blank" rel="nofollow">JAQUE Software</a></span>
    </v-footer>
  </v-app>
</template>

<script>

  export default {
    data: () => ({
      drawer: null,
    }),

    methods: {
      logout: function () {
        this.$soapClient.call('logout')
        sessionStorage.removeItem('token')
        this.$store.state.loggedIn = false
        this.$router.push({name: 'login'})
      },
    },
    created: function () {
      this.$eventHub.$on('open-menu', () => this.drawer = true)
    },
    beforeDestroy: function () {
      this.$eventHub.$off('open-menu')
    },
  }
</script>

<style scoped>
  .footer {
    font-size: 14px;
  }
  .footer a {
    color: #1976d2;
    text-decoration: none;
    font-weight: bold;
  }
  >>>.theme--light.v-icon {
    color: #1976d2;
  }
  >>>.v-list .v-list-item--active .v-icon {
    color: #1976d2;
  }
</style>
