import { required, email, min, max, confirmed } from "vee-validate/dist/rules";
import { localize, extend } from "vee-validate";
import es from 'vee-validate/dist/locale/es.json';

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("confirmed", confirmed);

localize('es', es);
