<template>

  <svg v-if="name === 'nav-facturas'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.59 36.59">
    <path d="M28.21,9.59a.54.54,0,0,0-.17-.4l-4.39-4.4h0a.61.61,0,0,0-.41-.17H11A1.21,1.21,0,0,0,9.82,5.83V27.94A1.21,1.21,0,0,0,11,29.15H27a1.21,1.21,0,0,0,1.21-1.21V9.59h0M23.88,9A.06.06,0,0,1,23.82,9V6.58L26.25,9ZM27,28H11a.06.06,0,0,1-.06-.06V5.83A.06.06,0,0,1,11,5.77H22.67V9a1.21,1.21,0,0,0,1.21,1.21h3.18V27.94A.06.06,0,0,1,27,28Z" style="fill:currentColor;"/>
    <path d="M24.1,19.41h-3a.58.58,0,1,0,0,1.15h3a.58.58,0,0,0,0-1.15" style="fill:currentColor;"/>
    <path d="M16,14.59c.83,0,1.53.52,1.53,1.15s-.7,1.15-1.53,1.15a1.67,1.67,0,0,1-1.09-.37.77.77,0,0,0-1,1.19,3.14,3.14,0,0,0,1.29.61v.86a.77.77,0,0,0,1.54,0v-.86A2.78,2.78,0,0,0,19,15.74,2.91,2.91,0,0,0,16,13.05c-.83,0-1.53-.52-1.53-1.15s.7-1.15,1.53-1.15a1.67,1.67,0,0,1,1.09.37.77.77,0,0,0,1-1.19,3.14,3.14,0,0,0-1.29-.61V8.45a.77.77,0,0,0-1.54,0v.87a2.78,2.78,0,0,0-2.3,2.58A2.91,2.91,0,0,0,16,14.59" style="fill:currentColor;"/>
    <path d="M24.1,22.87H13.94a.58.58,0,0,0-.58.58.57.57,0,0,0,.58.57H24.1a.57.57,0,0,0,.57-.57.58.58,0,0,0-.57-.58" style="fill:currentColor;"/>
    <path d="M24.1,16h-3a.57.57,0,0,0-.58.57.58.58,0,0,0,.58.58h3a.58.58,0,0,0,.57-.58A.57.57,0,0,0,24.1,16" style="fill:currentColor;"/>
    <path d="M24.1,12.48h-3a.58.58,0,1,0,0,1.15h3a.58.58,0,0,0,0-1.15" style="fill:currentColor;"/>
  </svg>

  <svg  v-else-if="name === 'nav-pedido'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.59 36.59">
    <path d="M20.58,8.45H14.21a.73.73,0,0,1,0-1.46h6.37a.73.73,0,1,1,0,1.46m-.67,7h-5v-6h5Zm0,12.13a2.52,2.52,0,1,1-5,0V16.5h5ZM20.58,6H14.21a1.73,1.73,0,0,0-.34,3.43v18.2a3.52,3.52,0,1,0,7,0V9.42A1.73,1.73,0,0,0,20.58,6Z" style="fill:currentColor;"/>
    <path d="M16.11,22.2a.5.5,0,0,0-.5.5v.62a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5V22.7a.51.51,0,0,0-.5-.5" style="fill:currentColor;"/>
    <path d="M16.11,17.39a.5.5,0,0,0-.5.51v2.9a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5V17.9a.51.51,0,0,0-.5-.51" style="fill:currentColor;"/>
  </svg>

  <svg v-else-if="name === 'nav-contacto'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.59 36.59">
    <path d="M27.42,17.37s0,0,0,0,0,.1,0,.22h0a8,8,0,0,1-.58,1.69,0,0,0,0,1,0,0c-1,2.29-3.09,5.8-7.71,9.61-4.63-3.81-6.75-7.32-7.72-9.61,0,0,0,0,0,0a8.41,8.41,0,0,1-.57-1.69h0a.89.89,0,0,1,0-.22s0,0,0,0a8.72,8.72,0,0,1-.15-1.62,8.51,8.51,0,1,1,17,0,8.64,8.64,0,0,1-.15,1.62M19.07,5.85a9.93,9.93,0,0,0-9.91,9.91,9.69,9.69,0,0,0,.18,1.86A3,3,0,0,0,9.4,18a9.56,9.56,0,0,0,.67,1.95c1.06,2.52,3.41,6.38,8.56,10.51a.74.74,0,0,0,.44.16.72.72,0,0,0,.44-.16c5.15-4.13,7.5-8,8.57-10.51A9.53,9.53,0,0,0,28.74,18c0-.19.06-.3.07-.33A10.55,10.55,0,0,0,29,15.76,9.93,9.93,0,0,0,19.07,5.85Z" style="fill:currentColor;"/>
    <path d="M19.07,20.76A4.89,4.89,0,1,1,24,15.87a4.9,4.9,0,0,1-4.89,4.89m0-11.18a6.29,6.29,0,1,0,6.29,6.29A6.29,6.29,0,0,0,19.07,9.58Z" style="fill:currentColor;"/>
  </svg>

  <svg v-else-if="name === 'nav-protocolos'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.59 36.59">
    <path d="M27.49,11a.58.58,0,0,0-.17-.4L22.92,6.2h0a.53.53,0,0,0-.4-.17H10.31A1.21,1.21,0,0,0,9.1,7.24V29.35a1.21,1.21,0,0,0,1.21,1.21h16a1.21,1.21,0,0,0,1.21-1.21V11h0m-4.34-.56a.06.06,0,0,1-.06-.06V8l2.44,2.44Zm3.13,19h-16a.06.06,0,0,1-.06-.06V7.24a.06.06,0,0,1,.06-.06H21.94v3.19a1.21,1.21,0,0,0,1.21,1.2h3.19V29.35A.06.06,0,0,1,26.28,29.41Z" style="fill:currentColor;"/>
    <path d="M23.38,13.89H13.21a.56.56,0,0,0-.57.57.57.57,0,0,0,.57.58H23.38a.58.58,0,0,0,.57-.58.57.57,0,0,0-.57-.57" style="fill:currentColor;"/>
    <path d="M23.38,17.35H13.21a.58.58,0,0,0,0,1.15H23.38a.58.58,0,0,0,0-1.15" style="fill:currentColor;"/>
    <path d="M23.38,20.82H13.21a.56.56,0,0,0-.57.57.57.57,0,0,0,.57.58H23.38a.58.58,0,0,0,.57-.58.57.57,0,0,0-.57-.57" style="fill:currentColor;"/>
    <path d="M18.29,24.28H13.21a.58.58,0,0,0,0,1.15h5.08a.58.58,0,1,0,0-1.15" style="fill:currentColor;"/>
  </svg>

  <svg v-else-if="name === 'nav-resumen'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.59 36.59">
    <path d="M27.51,30.59h-15a.6.6,0,1,1,0-1.19h15a.36.36,0,0,0,.36-.36V10.26a.6.6,0,0,1,1.19,0V29a1.56,1.56,0,0,1-1.55,1.55m-1.92-1.92H12.46a.59.59,0,1,1,0-1.18H25.59a.36.36,0,0,0,.36-.37V8.35a.6.6,0,1,1,1.19,0V27.12A1.55,1.55,0,0,1,25.59,28.67ZM9.22,7.39V25.2a.37.37,0,0,0,.37.37H23.67A.37.37,0,0,0,24,25.2V7.39A.37.37,0,0,0,23.67,7H9.59A.37.37,0,0,0,9.22,7.39ZM23.67,26.76H9.59A1.55,1.55,0,0,1,8,25.2V7.39A1.54,1.54,0,0,1,9.59,5.84H23.67a1.55,1.55,0,0,1,1.55,1.55V25.2A1.56,1.56,0,0,1,23.67,26.76Z" style="fill-rule:evenodd;fill:currentColor;"/>
    <path d="M15.91,15.05h-4.6a.48.48,0,0,1-.48-.48V10a.49.49,0,0,1,.48-.48h4.6a.49.49,0,0,1,.48.48v4.6a.48.48,0,0,1-.48.48m-4.12-1h3.64V10.45H11.79Z" style="fill:currentColor;"/>
    <path d="M12.42,19.65h0a.48.48,0,0,1-.34-.14l-.93-.93a.47.47,0,0,1,0-.67.48.48,0,0,1,.68,0l.59.59,1.27-1.28a.5.5,0,0,1,.68,0,.48.48,0,0,1,0,.68l-1.61,1.61a.49.49,0,0,1-.34.14" style="fill:currentColor;"/>
    <path d="M12.42,23.31h0a.48.48,0,0,1-.34-.14l-.93-.93a.48.48,0,0,1,0-.68.5.5,0,0,1,.68,0l.59.6,1.27-1.28a.5.5,0,0,1,.68,0,.48.48,0,0,1,0,.68l-1.61,1.61a.49.49,0,0,1-.34.14" style="fill:currentColor;"/>
    <path d="M22.22,22.59H16.63a.6.6,0,0,1,0-1.19h5.59a.6.6,0,0,1,0,1.19M22,15.16H18.59a.59.59,0,0,1,0-1.18H22a.59.59,0,0,1,0,1.18Zm0-2.3H18.59a.59.59,0,0,1,0-1.18H22a.59.59,0,0,1,0,1.18Zm0-2.3H18.59A.58.58,0,0,1,18,10a.59.59,0,0,1,.59-.59H22a.6.6,0,0,1,.59.59A.59.59,0,0,1,22,10.56ZM22.22,19H16.63a.6.6,0,0,1,0-1.19h5.59a.6.6,0,0,1,0,1.19Z" style="fill-rule:evenodd;fill:currentColor;"/>
  </svg>

</template>

<script>

  export default {
    props: ['name']
  }

</script>
